import styled from '@emotion/styled';

export const GuestLoginDashboardComponent = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #191919;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .Registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    text-align: center;

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &__label {
      display: block;
      padding: 20px;
      font-size: 35px;
    }

    &__input {
      width: 90%;
      max-width: 400px;
      margin: 10px 0;

      &.error {
        border-color: red;
      }
    }

    &__error-message {
      color: red;
    }

    .captcha {
      margin: 20px 0;

      &.error {
        border-color: red;
      }
    }

    &__captcha-error {
      color: red;
    }
  }
`;
