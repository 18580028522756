

const LineChartSection = ({ title, linesArray, width, baseWidth, spacing, lineThickness, data }) => {
    return (
        <>
            <h3>{title}</h3>
            <svg width={width} height={linesArray.length * 50 + 50}>
                {linesArray.map((_, index) => {
                    const x1 = (width - baseWidth) / 2 + spacing * index;
                    const x2 = width - (width - baseWidth) / 2 - spacing * index;
                    const y = 50 + index * 50; // Espaciado vertical
                    const midX = (x1 + x2) / 2; // Posición horizontal del centro de la línea

                    return (
                        <g key={index}>
                            {/* Línea */}
                            <line
                                x1={x1}
                                y1={y}
                                x2={x2}
                                y2={y}
                                stroke="#242323"
                                strokeWidth={lineThickness}
                            />
                            {/* Texto centrado dentro de la línea */}
                            <text
                                x={midX}
                                y={y + 5}
                                fill="white"
                                fontSize="14"
                                textAnchor="middle"
                                dominantBaseline="middle"
                            >
                                {data[index]?.value}
                            </text>
                            <text
                                x={x2 + 20}
                                y={y + 5}
                                fill="black"
                                fontSize="14"
                                textAnchor="start"
                            >
                                {data[index]?.name}
                            </text>
                        </g>
                    );
                })}
            </svg>
        </>
    );
};

export default LineChartSection;
