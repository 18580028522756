import { useState, useEffect } from 'react';
import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';
import groupService from '../../../services/groupService';
import {
  LabelList,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from 'recharts';

const Groups = () => {
  const [groupedCategories, setGroupedCategories] = useState([]);
  const [groupedByActivityName, setGroupedByActivityName] = useState({});
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [totalLeaders, setTotalLeaders] = useState(0);
  const [memberData, setMemberData] = useState([]);
  const [growthData, setGrowthData] = useState([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalGroup, setTotalGroup] = useState(0);
  const [onlineStatusData, setOnlineStatusData] = useState([]);
  const [groupStatusData, setGroupStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const translationMap = {
    "Mixtos_Adultos_(26_a_40_años)": "Adultos (26 a 55)",
    "Parejas_(Casadas/_Unión_Libre_)": "Adultos (26 a 55)",
    "Mixtos_Adultos_(41_años_en_adelante)": "Adultos (26 a 55)",
    "Niños_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Hombres_jóvenes_Youth_(18-25_años)": "Jóvenes (18 a 25)",
    "Chicos_Cyphers_(14_a_17_años)": "Adolescentes(14 a 17)",
    "Mujeres_Adultas_(46_a_55_años)": "Adultos (26 a 55)",
    "Youth_Mixto_(18_-_25_años)": "Jóvenes (18 a 25)",
    "Niños_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mixto_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mujeres_Jóvenes_Adultas_(26_a_35_años)": "Adultos (26 a 55)",
    "Hombres_Adultos_(46_a_55_años)": "Adultos (26 a 55)",
    "Chicas_Cyphers_(14_a_17_años)": "Adolescentes(14 a 17)",
    "Mujeres_Adultas_(36_a_45_años)": "Adultos (26 a 55)",
    "Niñas_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Mujeres_jóvenes_Youth_(18-25_años)": "Jóvenes (18 a 25)",
    "Mujeres_Adultas_(Más_de_56_años)": "Adulto Mayor (mayor 55)",
    "Niñas_79_(7_a_9_años)": "Niños(7 a 13)",
    "Mixto_Nxt_(10_a_13_años)": "Niños(7 a 13)",
    "Hombres_Adultos_(36_a_45_años)": "Adultos (26 a 55)",
    "Hombres_Adultos_(26_a_35_años)": "Adultos (26 a 55)",
  };


  useEffect(() => {
    setLoading(true);
    const body = {
      activityName: 'Niños_79_(7_a_9_años)',
      noFilter: true,
      kind: 'Grupo_Discipulado',
    };

    groupService.getAllGroupsByLeader(body)
      .then((response) => {
        console.log(response);
        const groups = response.data.groups;

        const grouped = groups.reduce((acc, group) => {
          const activityName = group.activityName;
          const translatedName = translationMap[activityName] || activityName;

          if (!acc[translatedName]) {
            acc[translatedName] = [];
          }

          acc[translatedName].push(group);
          return acc;
        }, {});

        // Agregar categoría "Todos" con todos los grupos sin reducir
        grouped["Todos"] = groups;

        console.log('Agrupado', grouped);

        setGroupedCategories(Object.keys(grouped));
        setGroupedByActivityName(grouped);
        setLoading(false);
      });

  }, []);

  const changeCategory = (event) => {
    handleCategoryChange(event);
    handleCategoryChangeForMembers(event);
    handleCategoryChangeForGrowth(event);
    handleCategoryChangeForOnlineStatus(event);
    handleGroupStatusChange(event);
  };


  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const genderCounts = groups.reduce((acc, group) => {
        if (group.maleLeader && group.femaleLeader && !group.coupleleadered) {
          console.log(group);
        }

        if (group.maleLeader) {
          acc[group.maleLeader.gender] = (acc[group.maleLeader.gender] || 0) + 1;
        }
        if (group.femaleLeader) {
          acc[group.femaleLeader.gender] = (acc[group.femaleLeader.gender] || 0) + 1;
        }
        return acc;
      }, {});

      const leaderCount = Object.values(genderCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(genderCounts).map(([gender, count]) => ({
        name: gender,
        value: count,
        percentage: ((count / leaderCount) * 100).toFixed(1)
      }));

      setSelectedCategoryData(chartData);
      setTotalLeaders(leaderCount);
    } else {
      setSelectedCategoryData([]);
      setTotalLeaders(0);
    }
  };

  const handleCategoryChangeForMembers = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const memberGenderCounts = groups.reduce((acc, group) => {
        group.GroupMembers.forEach((member) => {
          const gender = member.male
            ? member.male.gender
            : member.female
              ? member.female.gender
              : null;
          if (gender) {
            acc[gender] = (acc[gender] || 0) + 1;
          }
        });
        return acc;
      }, {});

      const memberCount = Object.values(memberGenderCounts).reduce((sum, count) => sum + count, 0);

      const chartData = Object.entries(memberGenderCounts).map(([gender, count]) => ({
        name: gender,
        value: count,
        percentage: ((count / memberCount) * 100).toFixed(1)
      }));

      setMemberData(chartData);
      setTotalMembers(memberCount);
    } else {
      setMemberData([]);
      setTotalMembers(0);
    }
  };

  const handleCategoryChangeForGrowth = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const growthData = {};
      groups.forEach((group) => {
        const creationDate = new Date(group.createdAt);
        const today = new Date();
        const startYear = creationDate.getFullYear();
        const startMonth = creationDate.getMonth();
        const endYear = today.getFullYear();
        const endMonth = today.getMonth();

        for (let year = startYear; year <= endYear; year++) {
          for (
            let month = year === startYear ? startMonth : 0;
            month <= (year === endYear ? endMonth : 11);
            month++
          ) {
            const key = `${year}-${month < 10 ? `0${month}` : month}`;

            if (!growthData[key]) {
              growthData[key] = 0;
            }
            growthData[key] += group.GroupMembers.length;
          }
        }
      });

      const chartData = Object.entries(growthData)
        .map(([key, count]) => {
          const [year, month] = key.split('-');
          return {
            name: `${year}-${parseInt(month, 10) + 1}`,
            cant: count,
          };
        })
        .sort((a, b) => {
          const [yearA, monthA] = a.name.split('-').map(Number);
          const [yearB, monthB] = b.name.split('-').map(Number);
          return yearA === yearB ? monthA - monthB : yearA - yearB;
        });

      setGrowthData(chartData);
    } else {
      setGrowthData([]);
    }
  };

  const handleCategoryChangeForOnlineStatus = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const statusKey = group.online ? 'Online' : 'Presencial';
        if (!acc[statusKey]) {
          acc[statusKey] = 0;
        }
        acc[statusKey] += 1;

        return acc;
      }, {});

      const total = Object.values(statusCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        name: status,
        value: count,
        percentage: ((count / total) * 100).toFixed(1)
      }));

      setOnlineStatusData(chartData);
    } else {
      setOnlineStatusData([]);
    }
  };

  const handleGroupStatusChange = (event) => {
    const selectedCategory = event.target.value;
    if (selectedCategory) {
      const groups = groupedByActivityName[selectedCategory];

      const statusCounts = groups.reduce((acc, group) => {
        const isSuspended = group.suspended;
        if (isSuspended) {
          acc['Cerrados'] = (acc['Cerrados'] || 0) + 1;
        } else {
          acc['Abiertos'] = (acc['Abiertos'] || 0) + 1;
        }
        return acc;
      }, {});

      const totalGroups = Object.values(statusCounts).reduce((sum, count) => sum + count, 0);
      const chartData = Object.entries(statusCounts).map(([status, count]) => ({
        name: status,
        cant: count,
        percentage: ((count / totalGroups) * 100).toFixed(1)
      }));

      setGroupStatusData(chartData);
      setTotalGroup(totalGroups);
    } else {
      setGroupStatusData([]);
    }
  };


  // Colores para el gráfico
  const COLORS = ['#f65d0a', '#32ccc4', '#FFBB28', '#FF8042'];

  return (
    <DashboardGroupsComponent>
      {loading ? (
        <div className='containerSpinner'>
          <div className='spinner'></div>
        </div>
      ) : (
        <>
          <h2 className='h2Title'>Grupos</h2>

          <div className="container">
            <h4 className='h4Title'>Seleccione una categoría</h4>
            <select
              onChange={changeCategory}
              className='selectCategory'
            >
              <option value="">Ninguna</option>
              {groupedCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          <div className="container-grafic">
            <div className="grafic">
              {selectedCategoryData.length > 0 && (
                <>
                  <h3>Líderes Totales: {totalLeaders}</h3>
                  <PieChart width={400} height={400}>
                    <Pie
                      data={selectedCategoryData}
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ value, percentage }) => `${value} (${percentage}%)`}
                    >
                      {selectedCategoryData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </>
              )}
            </div>

            <div className="grafic">
              {memberData.length > 0 && (
                <>
                  <h3>Discípulos Totales: {totalMembers}</h3>
                  <PieChart width={450} height={400}>
                    <Pie
                      data={memberData}
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      label={({ value, percentage }) => `${value} (${percentage}%)`}
                    >
                      {memberData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </>
              )}
            </div>

            <div className="grafic">
              <h3>Crecimiento 2019-2024</h3>
              <LineChart width={600} height={300} data={growthData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="cant" stroke="#f65d0a" />
              </LineChart>
            </div>

            <div className="grafic">
              <h3>Modalidades</h3>
              <PieChart width={400} height={400}>
                <Pie
                  data={onlineStatusData}
                  cx="50%"
                  cy="50%"
                  innerRadius={100}
                  outerRadius={150}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ value, percentage }) => `${value} (${percentage}%)`}
                >
                  {onlineStatusData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>

            <div className="grafic">
              <h3>Estado de Grupos: {totalGroup}</h3>
              <BarChart width={600} height={300} data={groupStatusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="cant" fill="#ffb290">
                  <LabelList dataKey={(entry) => `${entry.cant} (${entry.percentage}%)`} position="top" />
                </Bar>
              </BarChart>
            </div>

            <div className="grafic">
              <h3>Tipo de Líderes</h3>

            </div>

          </div>
        </>
      )}
    </DashboardGroupsComponent>
  );
};

export default Groups;
