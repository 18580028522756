import styled from '@emotion/styled';

export const DashboardGroupsComponent = styled.div`
  width: 85%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px 0px 0px 0px;
  position: fixed;
  right: 0px;
  overflow: auto;

  .h2Title {
    color: '#009700';
    font-size: '40px';
  }

  .h4Title {
    margin-bottom: 4px;
    margin-top: 10px;
  }

  .selectCategory {
    padding: '10px';
    border-radius: '10px';
    border: '1px solid #21ba45';
  }

  .container-grafic {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    margin-bottom: 10%;
    justify-content: center;
    align-items: center;
  }

  .grafic {
    flex: 1 1 300px; 
    max-width: 550px; 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; 
    margin: 0;
    margin-bottom: 50px;
  }

  h3 {
    color: #363636;
    font-size: 30px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 0px;
  }
`;

