import React from 'react';
import { Input, Select, Button } from 'semantic-ui-react';
import { formInput, formSelectService } from '../helpers';
import { SingleDatePicker } from 'react-dates';
import moment from '../../services/moment_instance';
import UploadProfilePhoto from '../helpers/guests/upload_photo';

export const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
  let i;
  let years = [];
  for (i = moment().year() + 5; i >= moment().year() - 20; i--) {
    years.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>
    );
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select value={month.month()} onChange={(e) => onMonthSelect(month, e.target.value)}>
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)}>
          {years}
        </select>
      </div>
    </div>
  );
};

const Form = (props) => {
  const {
    sendInForm,
    handleInputs,
    submit,
    loadedData,
    Attendant,
    errorInputs,
    showGender,
    showDate,
    showBirthdate,
    moreInfo,
    showIdentification,
    showPhone,
    showEmail,
    showAge,
    showOcupation,
    image,
    set_image,
  } = props;
  const { lastName, name, phone, identification, createdAt, email, gender, birthdate, ocupation } =
    Attendant || {};
  const [focused, set_focused] = React.useState(false);

  const selectHandle = (a, { value, name }) => handleInputs({ target: { value, name } });

  return (
    <>
      {set_image && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <UploadProfilePhoto
            name={'image'}
            labelDescription={'foto'}
            errorInputs={errorInputs}
            imageFile={image}
            filling
            inputs={Attendant}
            set_image={set_image}
          />
        </div>
      )}
      {formInput({
        Input,
        handler: handleInputs,
        label: 'Nombres',
        name: 'name',
        value: name || '',
        errorInputs,
      })}

      {formInput({
        Input,
        handler: handleInputs,
        label: 'Apellidos',
        name: 'lastName',
        value: lastName || '',
        errorInputs,
      })}

      {showIdentification &&
        formInput({
          Input,
          handler: handleInputs,
          type: 'number',
          label: 'Identificación',
          name: 'identification',
          value: identification || '',
          errorInputs,
        })}

      {showPhone &&
        formInput({
          Input,
          handler: handleInputs,
          label: 'Celular (WhatsApp)',
          name: 'phone',
          value: phone || '',
          errorInputs,
          type: 'number',
        })}

      {showEmail &&
        formInput({
          Input,
          handler: handleInputs,
          label: 'Correo Electrónico',
          name: 'email',
          value: email || '',
          errorInputs,
          type: 'email',
        })}
      {showOcupation &&
        formInput({
          Input,
          handler: handleInputs,
          label: 'Profesión',
          name: 'ocupation',
          value: ocupation || '',
          errorInputs,
          type: 'ocupation',
        })}
      {showGender &&
        formSelectService({
          Select,
          handler: selectHandle,
          label: 'Genero',
          name: 'gender',
          value: gender || '',
          errorInputs,
          options: ['Masculino', 'Femenino'],
        })}

      {showBirthdate && (
        <div className="birthdate" style={errorInputs['birthdate'] ? { color: 'red' } : {}}>
          <label htmlFor={'birthdate'}>Fecha de nacimiento</label>{' '}
          <SingleDatePicker
            date={new Date(birthdate) === 'Invalid Date' || !birthdate ? null : moment(birthdate)}
            onDateChange={(newDate) =>
              handleInputs({
                target: {
                  value: newDate ? newDate._d : newDate,
                  name: 'birthdate',
                },
              })
            }
            focused={focused}
            onFocusChange={({ focused }) => set_focused(focused)}
            placeholder="dd/mm/aaaa"
            showDefaultInputIcon={true}
            numberOfMonths={1}
            displayFormat="DD/MM/YYYY"
            isOutsideRange={() => false}
            id="birthdate"
            renderMonthElement={renderMonthElement}
          />{' '}
          {new Date(birthdate) === 'Invalid Date' || !birthdate
            ? null
            : moment(birthdate).format('DD-MMMM-YYYY')}
          {!birthdate && (
            <p>
              <small>
                Llena la fecha de nacimiento en números asi: dd/mm/aaaa (día/mes/año), ejemplo:
                31/12/2000
              </small>
            </p>
          )}
        </div>
      )}
      {showAge && new Date(birthdate) !== 'Invalid Date' && (
        <p style={{ margin: '10px 0' }}>
          Edad: <strong>{moment().diff(birthdate, 'years')} años</strong>
        </p>
      )}
      {createdAt && showDate && (
        <p style={{ display: 'inline-block' }}>
          Creado: {moment.tz(createdAt, 'America/Bogota').format('DD-MM-YYYY, h:mm:ss a')}
        </p>
      )}
      {moreInfo && moreInfo()}
      {sendInForm && (
        <div>
          <Button disabled={loadedData} loading={loadedData} onClick={(e) => submit(e)} primary>
            {props.saveTag}
          </Button>
        </div>
      )}
    </>
  );
};

Form.defaultProps = {
  showGender: false,
  showBirthdate: false,
  showIdentification: true,
  showPhone: true,
  showEmail: true,
};

export default Form;
