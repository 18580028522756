import { useContext } from 'react';
import { DashboardGroupsComponent } from './styles/DashboardGroupsComponent';
import { GuestDashboardContext } from './guestDashboardContext';
import moment from 'moment';
import { weekdays } from '../../helpers';
import { getGroupLeaders } from '../../groups/registration/leaderSelect';

const Groups = () => {
  const { guestGroup, guestGroups, guest } = useContext(GuestDashboardContext);

  const groupMembersGroup = guestGroup?.groupMembers;
  return (
    <DashboardGroupsComponent>
      <h3 style={{ marginBottom: 0, fontSize: '35px', fontWeight: '500' }}>Estos son tus</h3>
      <h2 style={{ color: '#191919', fontSize: '40px' }}>Grupos</h2>
      <div className="columnsGroup">
        <div className="columnGroup">
          <div className="container-group">
            <p style={{ fontSize: '18px', marginBottom: 3, color: '#878787' }}>
              Haces parte del grupo
            </p>
            {!!groupMembersGroup?.length ? (
              groupMembersGroup?.map((group, index) => (
                <div className={`container-group-in ${group.pending ? 'pending' : ''}`} key={index}>
                  {group.pending && <div className='pending-banner'>Pendiente de aprobación</div>}
                  <p
                    style={{ fontSize: '25px', fontWeight: 600, color: '#009700', marginBottom: 0 }}
                  >
                    {group?.ChurchGroup?.groupName}
                  </p>
                  <p style={{ marginBottom: 0 }}>{getGroupLeaders(group?.ChurchGroup)}</p>
                  <div className="columnsGroup">
                    <div>
                      <p className="subtitle">Modalidad</p>
                      <p>{group?.ChurchGroup?.online ? 'Virtual' : 'Presencial'}</p>
                    </div>
                    <div>
                      <p className="subtitle">Horario</p>
                      <p>
                        {weekdays[group?.ChurchGroup?.dayOfWeek]}{' '}
                        {moment(group?.ChurchGroup?.time, 'HH:mm').format('hh:mm a z')}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>
                No haces parte de ningún grupo,{' '}
                <a className='link' href="https://www.comunidadmde.com/grupos" target="_blank" rel="noreferrer">
                  haz clic aqui para comenzar a hacer parte de una Comunidad.
                </a>
              </p>
            )}
            <br></br>
            <p style={{ fontSize: '18px', marginBottom: 3, color: '#878787' }}>
              Estos son tus grupos.
            </p>
            <br></br>
            <div className="your-group">
              {!!guestGroups?.length ? (
                guestGroups?.map?.((group, index) => (
                  <button
                    key={index}
                    className="group-button"
                    onClick={() =>
                      window.open(
                        `/grupos/lider?phone=${guest?.phone}&identification=${guest?.identification}&activityName=${group.activityName}`,
                        '_blank'
                      )
                    }
                  >
                    {group.groupName}
                    <p>{group.activityName.replace(/_/g, ' ')}</p>
                  </button>
                ))
              ) : (
                <p>
                  No estás liderando un grupo en este momento
                  {!!groupMembersGroup?.length && (
                    <a href="https://www.comunidadmde.com/grupos" target="_blank" rel="noreferrer">
                      , haz clic aqui para comenzar a liderar en Comunidad.
                    </a>
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </DashboardGroupsComponent>
  );
};

export default Groups;
