import styled from '@emotion/styled';

export const DashboardExpComponent = styled.div`
  width: 85%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fafdff;
  border-radius: 20px 0px 0px 0px;
  position: fixed;
  right: 0px;
  overflow: auto;
  h2 {
    margin-bottom: 20px;
    color: #434343;
  }

  .select-container {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  }

  .charts-container {
    width: 100%;
    max-height: 530px;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .chart-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    margin: 30px 0;
  }

  .subTitle {
    display: flex;
    justify-content: center;
  }

   .tabs {
    display: flex;
    gap: 10px;
  }

  .tab {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #434343;
    background: white;
    color: #434343;
    cursor: pointer;

    &.active {
      background: #434343;
      color: white;
    }
  }

  .tab-content {
    width: 100%;
    height: 100%;
  }

  .global-content, .area-content {
    width: 100%;
    height: 100%;
    display: flex;
  }

`;

