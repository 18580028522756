import styled from '@emotion/styled';

export const DashboardCrecerComponent = styled.div`
  width: 85%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 20px 0px 0px 0px;
  position: fixed;
  right: 0px;
  overflow: auto;

  .tabs {
  background: white;
  color: #434343;
  border: 1px solid #434343;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}


.h2Title {
  color: '#009700';
  font-size: '40px';
}

.container-1 {
  width: 100%;
  max-height: 530px;
  overflow-y: auto;
}

.tabs.active {
  background: #434343;
  color: white;
}

.tabs.out {
  background: #434343;
  color: white;
}

.button {
  border: 1px solid #242323;
  border-radius: 10px;
  background: #242323;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease, transform 0.2s ease;
  font-size: 16px;
}

.button:active {
  box-shadow: none;
  transform: translateY(2px);
}

.inputs {
  border-radius: 10px;
  border: 1px solid #242323;
  padding: 5px;
}

.labels {
  margin-right: 8px;
}

.whiteSpace {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}





`;

